<template>
  <div class="huiwen-container huiwen-left">
    <a-drawer
      title="训练结果分析"
      placement="right"
      :closable="false"
      :visible="visible"
      @close="onClose"
      width="400"
    >
      <template v-if="mode == '0'">
        <a-row>
          <div id="practice_res_chart"></div>
        </a-row>
        <a-row class="huiwen-margin-top-20">
          <div v-if="details && details.length > 0" class="huiwen-container huiwen-left">
            <div v-for="(item, index) in details" class="huiwen-left user-answer-info huiwen-layout-flex-center">
              <div class="item">
                <div class="huiwen-container huiwen-left" style="height: 12px;"></div>
                <div class="huiwen-container huiwen-left huiwen-text-center huiwen-text-common" style="height: 16px;line-height: 16px;color: #494949">{{index+1}}</div>
                <div class="huiwen-container huiwen-left" style="height: 12px;line-height: 12px">
                  <icon-font v-if="item.is_correct == 1" type="icon-icon" style="color: #67C23A;float: right;font-size: 12px;"></icon-font>
                  <icon-font v-else-if="item.is_correct == 0" type="icon-icon1" style="color: #F56C6C;float: right;font-size: 12px;"></icon-font>
                  <icon-font v-else type="icon-weiwancheng" style="color: #F56C6C;float: right;font-size: 35px;margin-top: -10px"></icon-font>
                </div>
              </div>
            </div>
          </div>
        </a-row>
      </template>
      <template v-else-if="mode == '1' || mode == '2'">

        <a-row>
          <a-col :span="8">
            <a-statistic title="业务得分" :value="total_score_biz"></a-statistic>
          </a-col>
          <a-col :span="8">
            <a-statistic title="质检得分" :value="total_score_qc"></a-statistic>
          </a-col>
          <a-col :span="8">
            <a-statistic title="综合得分" :value="total_score"></a-statistic>
          </a-col>
        </a-row>
        <a-row class="huiwen-margin-top-20">
          <div id="practice_score_chart"></div>
        </a-row>
        <a-row class="huiwen-margin-top-20">
          <a-table :columns="columns" :data-source="qc_data" size="middle" :pagination="false" />
        </a-row>

      </template>
    </a-drawer>
  </div>
</template>

<script>
  import { doQuery, doAction } from "@/api/core"
  import { Chart, Util } from '@antv/g2'
  import DataSet from '@antv/data-set'

  export default {
    name: 'PracticeResultDrawer',
    data() {
      return {
        visible: false,
        loading: false,
        qc_data: [],
        chart_data: [
          {
            name: '情绪态度',
            part: 0
          },
          {
            name: '服务规范',
            part: 0
          },
          {
            name: '禁忌用语',
            part: 0
          },
          {
            name: '关键词命中',
            part: 0,
            is_biz: '1'
          },
          {
            name: '答案匹配度',
            part: 0,
            is_biz: '1'
          }
        ],
        total_score: 0,
        total_score_biz: 0,
        total_score_qc: 0,

        // 评分雷达图
        practice_score_chart: null,


        practice_res_chart: null,
        scores: null,
        details: null,
      }
    },
    props: {
      session_id: String,
      task_id: String,
      mode: String
    },
    watch: {
      'mode': function(newVal, oldVal) {
        if(newVal) {
          this.visible = true
          this.qc()
            .then(() => {
              this.drawResultChart()
            })
        }
      }
    },
    computed: {
      columns(){
        return [
          {
            title: '类别',
            dataIndex: 'qctype',
          },{
            title: '检测项目',
            dataIndex: 'qcsubtype',
          },{
            title: '扣分',
            dataIndex: 'result',
            customRender: (text, record, index) => {
              if(text > 0) {
                return <a-tag color="#f50">{ text }</a-tag>
              }else if(text == 0) {
                return <a-tag color="#cecece">{ text }</a-tag>
              }else if(text < 0) {
                return <a-tag color="#f50">{ text }</a-tag>
              }
            }
          }
        ]
      }
    },
    methods: {
      initData() {
        const that = this
        that.qc_data = []
        that.chart_data = [
          {
            name: '情绪态度',
            part: 0
          },
          {
            name: '服务规范',
            part: 0
          },
          {
            name: '禁忌用语',
            part: 0
          },
          {
            name: '关键词命中',
            part: 0,
            is_biz: '1'
          },
          {
            name: '答案匹配度',
            part: 0,
            is_biz: '1'
          }
        ]
        that.total_score = 0
        that.total_score_biz = 0
        that.total_score_qc = 0
        // 评分雷达图
        that.practice_score_chart = null

        that.practice_res_chart = null
        that.scores = null
        that.details = null
      },
      onClose() {
        const that = this
        that.visible = false
        that.initData()
        that.$emit('close')
      },
      qc() {
        const that = this
        return new Promise((resolve, reject) => {
          if(that.mode == '0') {
            doQuery({
              query_code: 'CCAI40',
              param_str2: that.session_id,
              page: 1,
              limit: 1
            }).then(res => {
              if (res.errcode >= that.$consts.biz.OK) {
                that.scores = res.data
                if (that.scores[0].cnt + that.scores[1].cnt>0) {
                  that.scores[0].percent = parseFloat((that.scores[0].cnt/(that.scores[0].cnt + that.scores[1].cnt)).toFixed(2))
                  that.scores[1].percent = parseFloat((that.scores[1].cnt/(that.scores[0].cnt + that.scores[1].cnt)).toFixed(2))
                  resolve()
                }
              }
            })

            doQuery({
              query_code: 'CCAI41',
              param_str2: that.session_id,
              page: 1,
              limit: 1
            }).then(res => {
              if (res.errcode >= that.$consts.biz.OK) {
                that.details = res.data
              }
            })


          }else if(that.mode == '1') {
            doQuery({
              query_code: 'CCAIS3',
              param_str2: that.session_id,
              page: 1,
              limit: 1
            }).then(res => {
              if (res.errcode >= that.$consts.biz.OK) {
                that.qc_data = res.data
                //生成图表数据
                var scoretotal = 0
                var scoretotal_biz = 0
                var scoretotal_qc = 0
                var cnt_biz = 0
                var cnt_qc = 0

                that.chart_data.forEach((item, index) => {
                  let scoresum = 0
                  for (let i = 0; i < that.qc_data.length; i++) {
                    if (that.qc_data[i].qctype == item.name) {
                      scoresum = scoresum + that.qc_data[i].result
                      if (item.is_biz == '1'){
                        scoretotal_biz = scoretotal_biz + that.qc_data[i].score_weight
                        scoretotal = scoretotal + that.qc_data[i].score_weight
                      }else {
                        scoretotal_qc = scoretotal_qc + that.qc_data[i].score_weight
                        scoretotal = scoretotal + that.qc_data[i].score_weight
                      }
                    }
                  }
                  //该大项的最终得分
                  item.part = (100 + scoresum) / 100
                  item.star = 100 + scoresum
                })

                //平均分
                that.total_score = scoretotal.toFixed(2)
                that.total_score_biz = scoretotal_biz.toFixed(2)
                that.total_score_qc = scoretotal_qc.toFixed(2)
                resolve()
              }else {
                that.qc_data = []
                that.$message.error('查询数据失败...')
              }
            })
          }else if(that.mode == '2') {
            doQuery({
              query_code: 'CCA1',
              param_str2: that.task_id,
              page: 1,
              limit: 1
            }).then(res => {
              if(res.errcode >= that.$consts.biz.OK) {
                doQuery({
                  query_code: 'CC004',
                  param_str2: that.task_id,
                  page: 1,
                  limit: 1
                }).then(res => {
                  if (res.errcode >= that.$consts.biz.OK){
                    that.qc_data = res.data
                    //生成图表数据
                    var scoretotal = 0
                    var scoretotal_biz = 0
                    var scoretotal_qc = 0
                    var cnt_biz = 0
                    var cnt_qc = 0
                    //针对每一个评估大项，汇总返回数据中扣分总和
                    that.chart_data.forEach((item, index) => {
                      let scoresum = 0
                      for (let i = 0; i < that.qc_data.length; i++) {
                        if (that.qc_data[i].qctype == item.name) {
                          scoresum = scoresum + that.qc_data[i].result

                          if (item.is_biz == '1'){
                            scoretotal_biz = scoretotal_biz + that.qc_data[i].score_weight
                            scoretotal = scoretotal + that.qc_data[i].score_weight
                          }else {
                            scoretotal_qc = scoretotal_qc + that.qc_data[i].score_weight
                            scoretotal = scoretotal + that.qc_data[i].score_weight
                          }

                        }
                      }
                      //该大项的最终得分
                      item.part = (100 + scoresum) / 100
                      item.star = 100 + scoresum

                    })
                    //平均分
                    that.total_score = scoretotal.toFixed(2)
                    that.total_score_biz = scoretotal_biz.toFixed(2)
                    that.total_score_qc = scoretotal_qc.toFixed(2)
                    resolve()

                  }else {
                    that.qc_data = []
                    that.$message.error('查询数据失败...')
                  }
                })
              }
            })
          }
        })
      },
      drawResultChart() {
        const that = this

        if(that.mode == '0') {
          var data = []
          that.scores.forEach(item => {
              data.push({
                type: item.result,
                value: item.percent,
                cnt: item.cnt
              })
          })
          if(that.practice_res_chart == null) {
            var chart = new Chart({
              container: 'practice_res_chart',
              autoFit: true,
              height: 300
            })
            chart.data(data)

            chart.coordinate('theta', {
              radius: 0.75
            })
            chart.tooltip({
              showMarkers: false
            })
            const interval = chart
              .interval()
              .adjust('stack')
              .position('cnt')
              .color('type', ['#67C23A', '#F56C6C'])
              .style({ opacity: 0.4 })
              .state({
                active: {
                  style: (element) => {
                    const shape = element.shape
                    return {
                      matrix: Util.zoom(shape, 1.1),
                    }
                  }
                }
              })
              .label('type', (val) => {
                const opacity = 1
                return {
                  offset: -30,
                  style: {
                    opacity,
                    fill: 'white',
                    fontSize: 12,
                    shadowBlur: 2,
                    shadowColor: 'rgba(0, 0, 0, .45)',
                  },
                  content: (obj) => {
                    return obj.type + '\n' + obj.value*100 + '%'
                  },
                }
              })
            chart.interaction('element-single-selected')
            that.practice_res_chart = chart
            that.practice_res_chart.render()
          }else {
            that.practice_res_chart.changeData(that.scores)
          }
        }else if(that.mode == '1' || that.mode == '2') {
          that.chart_data.forEach(item => {
            item['得分'] = item.star
          })

          const dv = new DataSet.DataView().source(that.chart_data)

          dv.transform({
            type: 'fold',
            fields: ['得分'], // 展开字段集
            key: 'type', // key字段
            value: 'score', // value字段
          })

          if(that.practice_score_chart == null) {
            var chart = new Chart({
              container: 'practice_score_chart',
              autoFit: true,
              height: 300,
              padding: [10,10,10,10]
            })
            chart.data(dv.rows)
            chart.scale('score', {
              min: 0,
              max: 100,
            })
            chart.coordinate('polar', {
              radius: 0.8,
            })
            chart.tooltip({
              shared: true,
              showCrosshairs: true,
              crosshairs: {
                type: 'score',
                line: {
                  style: {
                    lineDash: [4, 4],
                    stroke: '#333'
                  }
                }
              }
            })
            chart.axis('score', {
              line: null,
              tickLine: null,
              grid: {
                line: {
                  type: 'line',
                  style: {
                    lineDash: null,
                  },
                },
              },
            })

            chart
              .line()
              .position('name*score')
              .color('type')
              .size(2)
            chart
              .point()
              .position('name*score')
              .color('type')
              .shape('circle')
              .size(4)
              .style({
                stroke: '#fff',
                lineWidth: 1,
                fillOpacity: 1,
              })
            chart
              .area()
              .position('name*score')
              .color('type')

            that.practice_score_chart = chart
            that.practice_score_chart.render()
          }else {
            that.practice_score_chart.changeData(dv.rows)
          }
        }
      }
    }
  }
</script>

<style scoped>

  /deep/ .ant-statistic-title {
    text-align: center;
  }

  /deep/ .ant-statistic-content {
    text-align: center;
  }

  .user-answer-info {
    width: 20%;
    height: 60px;
  }

  .user-answer-info .item {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: #f6f6f9;
  }

</style>