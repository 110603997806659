<template v-if="options.length > 0">
    <div style="width: 100%;float: left;">
        <div v-for="(item, index) in options" v-if="item.text && item.text!=''" class="radio-row wcs-top-10 wcs-bottom-10"
              @click="selectOption(index+1)">
            <div :style="{
				'height': rowHeight+'px',
				'line-height': rowHeight+'px',
				'border-radius': '20px',
				'font-size': rowHeight-6+'px',
				'width': rowHeight+'px'
				}"
                  :class="selectDataOption && selectDataOption.indexOf(index+1) > -1 ? 'option-select' : 'option'">
                {{item.number}}
            </div>
            <div :style="{
				'line-height': (rowHeight+2)+'px',
				'font-size': (rowHeight-3)+'px',
				'width': 'calc(100% - '+(rowHeight+10)+'px)'
			}"
                  :class="selectDataOption && selectDataOption.indexOf(index+1) > -1 ? 'text-select' : 'text'">
                {{item.text}}
            </div>
        </div>
    </div>
</template>

<script>
    import consts from '@/constants'

    export default {
        name: 'wcs-radio',
        props: {

            //本题类型
            type: {
                type: String,
                default: consts.biz.QUES_TYPES.SINGLE
            },

            //高度
            rowHeight: {
                type: Number,
                default: 18
            },

            //答案选项
            options: {
                type: Array,
                default: [],
            },

            //选中答案，null代表还没选择答案
            selectDataOption: {
                type: String,
                default: null,
            },

            /**
             * 是否只读，在答案解析页面中，选项不允许被点击
             */
            readOnly: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
            }
        },

        created() {
        },

        mounted() {
        },

        methods: {

            /**
             * 点击某个答案：通知父组件答案字符串
             */
            selectOption(index) {
                let that = this
                let selectData = that.selectDataOption

                if (index != null && !that.readOnly) {

                    let answer_index = "" + index;

                    //如果是单选题，或者还没有选择过答案
                    if (that.type === consts.business.questiontypes.SINGLE || that.type === consts.business.questiontypes.JUDGE || selectData == null)
                        selectData = answer_index;
                    else {
                        //如果是多选题，而且已经选择过答案
                        if (selectData.indexOf(answer_index) >= 0)
                            selectData = selectData.replace(answer_index, '');
                        else
                            selectData = selectData + "|" + answer_index;


                        //删除1个多选答案以后，可能格式上需要调整，规范成“1｜2｜3｜4”这样的格式
                        selectData = selectData.replace(/\|\|/, '|');
                        if (selectData.charAt(selectData.length - 1) == '|')
                            selectData = selectData.substr(0, selectData.length - 1);
                        if (selectData.charAt(0) == '|')
                            selectData = selectData.substr(1, selectData.length - 1);
                        if (selectData == '')
                            selectData = null;

                    }

                    //只传递一个答案字符串出去
                    that.$emit('selectOption', selectData);
                }
            }
        }
    }
</script>

<style>
    .radio-row {
        width: 100%;
        float: left;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #f5f5f6;
    }

    .radio-row .option {
        border: #494949 solid 1px;
        text-align: center;
        color: #494949;
        float: left;

    }

    .radio-row .text {
        color: #494949;
        margin-left: 8px;
        float: left;
    }

    .radio-row .option-select {
        border: #1d88fa solid 1px;
        background-color: #1d88fa;
        text-align: center;
        color: #ffffff;
        float: left;
    }

    .radio-row .text-select {
        color: #1d88fa;
        margin-left: 8px;
        float: left;
    }
</style>
