import { mapGetters,mapMutations } from 'vuex'
import { doQuery,doAction } from "@/api/core"
import videojs from 'video.js';
import 'videojs-contrib-hls'
import E from "wangeditor";
import wcsRadio from '@/components/wcs-radio/wcs-radio.vue'

export default {
    name: "singleLesson",
    inject: ['reload'],
    computed: {
        ...mapGetters([
            'staffId',
            'mobilephone',
            'refreshTrainingTaskItem'
        ])
    },
    components: {
        wcsRadio
    },
    data(){
        return {
            lessonId: undefined,
            taskId: undefined,
            taskItemId: undefined,

            microLessonList: [],
            lessonCatelog: [],
            lessonAttaches: [],
            lessonPops: [],
            lessonPopIndex: 0,

            playIndex: null,
            playTime: 0,
            playSaveCount: 0,

            player: null,

            tabActive: 0,
            tabs: [
                {
                    name: 'catelog',
                    icon: 'icon-mulu-lujing',
                    text: '目录',
                },{
                    name: 'attaches',
                    icon: 'icon-wenjianjia',
                    text: '课件',
                }
            ],

            editor: null,
            editorContent: '',

            quesModel: false,
            quesModelBtnText: '提交答案'
        }
    },
    mounted() {
        if(this.$route.params) {
            if(this.$route.query.taskId) {
                this.taskId = this.$route.query.taskId
            }
            if(this.$route.query.taskItemId) {
                this.taskItemId = this.$route.query.taskItemId
            }
            if(this.$route.query.lessonId) {
                this.lessonId = this.$route.query.lessonId
            }

            this.queryMicroLessons()
        }
    },
    methods: {
        ...mapMutations([
            'SET_REFRESH_TRAINING_TASK_ITEM',
            'SET_TRAINING_VISIBLE'
        ]),

        //======================================= wangeditor begin ====================================================
        initWangEditor() {
            const that = this
            var editor = new E('#editorElem')
            editor.config.height = 200
            // 编辑器的事件，每次改变会获取其html内容
            editor.config.onchange = html => {
                that.editorContent = html;
            };
            editor.config.zIndex = 1
            editor.config.placeholder = '好记性不如烂笔头，来做点笔记吧！'
            editor.config.menus = [
                // 菜单配置
                'bold',
                'head',
                'fontSize',
                'link',
                'italic',
                'underline',
                'foreColor',
            ];
            editor.create(); // 创建富文本实例
            editor.txt.html(that.editorContent)
            that.editor = editor
        },

        //======================================= wangeditor end ====================================================


        //======================================= videojs begin ====================================================
        initVideoPlayer() {
            const that = this
            that.player = videojs(
                'videoPlayer',
                {
                    bigPlayButton: false,
                    textTrackDisplay: true,
                    posterImage: true,
                    errorDisplay: false,
                    controlBar: false,
                    playbackRates: [0.5, 1, 1.5, 2],
                    ControlBar: {
                        customControlSpacer: true,
                        progressControl: true,
                        currentTimeDisplay: true
                    },
                    notSupportedMessage: "此视频暂无法播放，请稍后再试",
                    sources: [
                        {
                            src: that.microLessonList[0].video_url,

                        }
                    ]
                },
                function () {
                    var component = videojs.getComponent("Component");
                    var shade = videojs.extend(component, {
                        constructor: function(player, options) {
                            component.apply(this, arguments);
                            this.updateContent();
                        },
                        // 创建一个DOM元素
                        createEl: function() {
                            return videojs.dom.createEl("div", {
                                style:
                                    "height:100%;width:100%;position: absolute;top:0;z-index: 1;background:rgba(0,0,0,0.5); display: flex;display: -webkit-flex;flex-direction:column;",
                                id: "vjs-shade"
                            });
                        },
                        updateContent: function(text) {
                            videojs.dom.emptyEl(this.el());
                            videojs.dom.appendContent(
                                this.el(),
                                videojs.dom.createEl("div", {
                                    style:
                                        "flex:1;display: flex;align-items: center;justify-content: center;",
                                    id: "shade-item-1"
                                })
                            );
                            videojs.dom.appendContent(
                                this.el(),
                                videojs.dom.createEl(
                                    "div",
                                    {
                                        style:
                                            "flex:1;display: flex;align-items: center;justify-content: center;color:white;font-size:20px",
                                        id: "shade-item-2"
                                    },
                                    {},
                                    that.microLessonList[0].title
                                )
                            );
                            videojs.dom.appendContent(
                                this.el(),
                                videojs.dom.createEl(
                                    "div",
                                    {
                                        style:
                                            "flex:1;display: flex;justify-content: center;color:white;font-size:16px",
                                        id: "shade-item-3"
                                    },
                                    {},
                                    that.microLessonList[0].kdbtype.concat(' | ').concat(that.microLessonList[0].create_time)
                                )
                            );
                            let btn =
                                videojs.dom.createEl(
                                    "i",
                                    {
                                        style:
                                            "color: #fff;cursor: pointer;font-size: 60px;",
                                        id: "shade-item-4-btn",
                                        className:'iconfont icon-bofang',
                                    },
                                )


                            let btnArea =
                                videojs.dom.createEl(
                                    "div",
                                    {
                                        style:
                                            "flex:4;display: flex;align-items: center;justify-content: center;",
                                        id: "shade-item-4"
                                    },
                                    {}
                                )

                            btnArea.appendChild(btn);

                            videojs.dom.appendContent(
                                this.el(),
                                btnArea
                            );
                            videojs.dom.appendContent(
                                this.el(),
                                videojs.dom.createEl("div", {
                                    style:
                                        "flex:1;display: flex;align-items: center;justify-content: center;",
                                    id: "shade-item-5"
                                })
                            );
                            videojs.dom.appendContent(
                                this.el(),
                                videojs.dom.createEl("div", {
                                    style:
                                        "flex:1;display: flex;align-items: center;justify-content: center;",
                                    id: "shade-item-6"
                                })
                            );
                            videojs.dom.appendContent(
                                this.el(),
                                videojs.dom.createEl("div", {
                                    style:
                                        "flex:1;display: flex;align-items: center;justify-content: center;",
                                    id: "shade-item-7"
                                })
                            );
                        }
                    })
                    videojs.registerComponent("Shade", shade);
                    videojs(that.$refs.videoRef).addChild("Shade");
                    document.getElementById("shade-item-4-btn").onclick = function () {
                        that.doPlayVideo()
                    }
                }
            )
        },
        onPlayerLoadedData(player) {},
        onPlayerTimeUpdate(timeupdate) {
            const that = this
            that.$nextTick(() => {
                const player = videojs(that.$refs.videoRef)
                var microLesson = [...that.microLessonList][0]
                var currentTime = timeupdate.target.currentTime
                var duration = timeupdate.target.duration

                if(microLesson.video_length == null) {
                    microLesson.video_length = duration
                }
                //更新本地的视频长度
                if(microLesson.limit_fastforward == '1' && microLesson.is_passed != '1' && currentTime > 0 && (currentTime - that.playTime) >= 5) {
                    that.$message.warning('该视频不支持快进，请完整观看')
                    that.seekPlay(that.playTime)
                    return
                }
                //如果当前进度时间大于0；而且和上次保存的时间差异超过3秒钟，则更新保存的时间
                if(currentTime > 0 && Math.abs(currentTime - that.playTime) >= 3) {
                    //变更播放进度
                    that.playTime = currentTime
                    that.playSaveCount = that.playSaveCount + 1

                    if(that.playSaveCount == 1 || that.playSaveCount % 20 == 0) {
                        that.saveProgress(currentTime,duration)
                    }
                    localStorage.setItem(that.$consts.storageKey.XST_VODEO_WATCH_COORDINATE_.concat(that.mobilephone).concat('_').concat(that.lessonId),Math.trunc(currentTime))

                    if (that.lessonPops && that.lessonPops.length > 0 && microLesson.is_passed != '1') {
                        for(let i=0; i < that.lessonPops.length; i++) {
                            //已经pop过了，忽略
                            if (that.lessonPops[i].hasPoped) {
                                continue
                            }

                            //没有pop过，但弹出时间到了;且当前时间和弹出时间差在4秒内，才会弹出（为了防止续看时重复弹出之前已经弹出的问题）
                            if (that.lessonPops[i].pop_time <= currentTime && currentTime - that.lessonPops[i].pop_time <= 4){
                                //inde修改，并且设置成已经弹出过了
                                that.lessonPopIndex = i;
                                that.lessonPops[i].hasPoped = true;

                                //暂停，并弹出
                                player.pause()
                                //todo
                                that.quesModelBtnText = '提交答案'
                                that.quesModel = true

                                break;

                            }
                        }
                    }

                }
            })
        },
        onPlayerPlay(e) {},
        onPlayerPlaying(player) {},
        onPlayerPause(player) {
            const that = this
            var begintime = localStorage.getItem(that.$consts.storageKey.XST_VODEO_WATCH_COORDINATE_.concat(that.mobilephone).concat('_').concat(that.lessonId))
            var duration = that.microLessonList[0].video_length
            if (begintime && duration) {
                that.saveProgress(begintime, duration);
            }
        },
        onPlayerEnded(player) {
            const that = this
            var duration = that.microLessonList[0].video_length
            if (duration) {
                that.saveProgress(duration, duration)
                localStorage.setItem(that.$consts.storageKey.XST_VODEO_WATCH_COORDINATE_.concat(that.mobilephone).concat('_').concat(that.lessonId),duration)
            }
        },
        onPlayerWaiting(player) {},
        onPlayerCanPlay(player) {},
        onPlayerCanPlayThrough(player) {},
        onPlayerStateChanged(playerCurrentState) {},
        playerReadied(player) {},

        /**
         * 跳跃到某个位置进行播放
         */
        seekPlay: function(startpos){

            var that = this;
            const player = videojs(that.$refs.videoRef)

            //此处必须大于等于0
            if (startpos == null || startpos < 0)
                return;

            //如果还没开始播放，则触发按钮时间，并传入开始时间
            if (player.getChild("Shade")){
                player.removeChild("Shade")
            }
            player.currentTime(startpos)
            player.removeChild("Shade")
            player.play()
        },

        doPlayVideo() {
            const that = this

            var curIdx = 0
            var begintime = localStorage.getItem(that.$consts.storageKey.XST_VODEO_WATCH_COORDINATE_.concat(that.mobilephone).concat('_').concat(that.lessonId))
            if (!begintime && that.microLessonList[0].item_progress){
                begintime = that.microLessonList[0].item_progress
            }
            if (begintime && begintime > 0) {
                if (that.microLessonList[0].item_progress > 0 && that.microLessonList[0].item_progress >= that.microLessonList[0].video_length) {
                    that.$confirm({
                        centered: true,
                        title: '温馨提示',
                        content: '本视频课程已经学习完成，是否重新观看？',
                        okText: '重新观看',
                        okType: 'primary',
                        cancelText: '取消',
                        onOk() {
                            begintime = 0
                            if (that.playIndex == null) {
                                that.playIndex = curIdx
                                that.seekPlay(begintime)
                            }
                        },
                        onCancel() {}
                    })
                }else {
                    that.$confirm({
                        centered: true,
                        title: '温馨提示',
                        content: '您上次观看到' + that.$cmyUtils.formatSeconds(begintime) + '，是否继续观看？',
                        okText: '继续观看',
                        okType: 'primary',
                        cancelText: '从头观看',
                        onOk() {
                            if (that.playIndex == null) {
                                that.playIndex = curIdx
                                that.playTime = begintime;
                                that.seekPlay(begintime)
                            }
                        },
                        onCancel() {
                            begintime = 0
                            if (that.playIndex == null) {
                                that.playIndex = curIdx
                                that.playTime = begintime;
                                that.seekPlay(begintime)
                            }
                        }
                    })
                }
            }else {
                if (that.playIndex == null) {
                    that.playIndex = curIdx
                    that.seekPlay(0)
                }
            }

        },

        //======================================= videojs end ====================================================

        queryMicroLessons() {
            const that = this
            var data = {}
            //只传入task：单次视频课程
            if (that.taskId && !that.lessonId) {
                data.query_code = 'LN70'
                data.param_str3 = that.taskId
                data.param_str4 = that.taskItemId ? that.taskItemId : ''
            }
            //只传入lessonid：非任务进入的课程
            if (!that.taskId && that.lessonId) {
                data.query_code = 'LN71'
                data.param_str3 = that.lessonId
            }

            //同时传入task_id,lessonid: 系列课进入某个课程
            if (that.taskId && that.lessonId) {
                data.query_code = 'LN72'
                data.param_str3 = that.taskId
                data.param_str4 = that.lessonId
            }
            data.page = 1;
            data.limit = 10;

            if(data.query_code) {
                doQuery(data).then(res => {
                    if(res.errcode === that.$consts.biz.OK && res.data && res.data.length > 0) {
                        that.getOtherInfo(res.data[0].lesson_id)
                        that.microLessonList = res.data
                        that.editorContent = res.data[0].notes

                        if(that.microLessonList.length > 0 && that.microLessonList[0].video_url) {
                            that.$nextTick(() => {
                                that.initVideoPlayer()
                            })
                        }
                        that.initWangEditor()

                        //如果是附件课程:图文课程，而且有video_url
                        if (that.microLessonList.length > 0 && that.microLessonList[0].media_type == '0' && that.microLessonList[0].video_url && that.microLessonList[0].video_url != '') {

                            let doc_type =  that.$cmyUtils.getFileType(that.microLessonList[0].video_url)
                            if (doc_type == 'pdf')
                                that.microLessonList[0].doc_type_icon = 'icon-PDF';
                            else if (doc_type == 'doc' || doc_type == 'docx')
                                that.microLessonList[0].doc_type_icon = 'icon-DOC';
                            else if (doc_type == 'ppt' || doc_type == 'pptx')
                                that.microLessonList[0].doc_type_icon = 'icon-PPT';
                            else if (doc_type == 'xls' || doc_type == 'xlsx')
                                that.microLessonList[0].doc_type_icon = 'icon-XLSX';
                            else
                                that.microLessonList[0].doc_type_icon = 'icon-shanchuwenjian';

                        }
                    }
                })
            }
        },
        getOtherInfo(lessonId) {
            const that = this
            doQuery({
                query_code: 'LN7A',
                param_str3: lessonId,
                page: 1,
                limit: 10
            }).then(res => {
                if(res.errcode === that.$consts.biz.OK && res.data) {
                    res.data.forEach((item, index) => {
                        if (item.time_length && item.time_length > 0 && item.time_length < 99999999) {
                            item.time_length_convert = that.$cmyUtils.formatSeconds(item.time_length);
                        }else {
                            item.time_length_convert = '未知'
                        }
                    })
                    that.lessonCatelog = res.data
                }else {
                    that.$message.error('哇哦，查询课程目录异常')
                }
            })
            doQuery({
                query_code: 'LN7B',
                param_str3: lessonId,
                page: 1,
                limit: 10
            }).then(res => {
                if(res.errcode === that.$consts.biz.OK && res.data) {
                    that.lessonAttaches = res.data
                }else {
                    that.$message.error('哇哦，查询课件信息异常')
                }
            })
            doQuery({
                query_code: 'LNPQ0',
                param_str3: lessonId,
                page: 1,
                limit: 10
            }).then(res => {
                if(res.errcode === that.$consts.biz.OK && res.data) {
                    res.data.forEach((item, index) => {
                        item.options = [
                            {
                                number: 'A',
                                text: item.answer1
                            },
                            {
                                number: 'B',
                                text: item.answer2
                            },
                            {
                                number: 'C',
                                text: item.answer3
                            },
                            {
                                number: 'D',
                                text: item.answer4
                            },
                            {
                                number: 'E',
                                text: item.answer5
                            },
                            {
                                number: 'F',
                                text: item.answer6
                            },
                        ]
                    })
                    that.lessonPops = res.data
                }else {
                    that.lessonPops = []
                }
                console.log(that.lessonPops)
            })
        },
        saveProgress(currentTime, duration) {
            const that = this
            that.microLessonList[0].item_progress = Math.trunc(currentTime)
            doAction({
                action_code: 'LNV0',
                param_str4: that.microLessonList[0].lesson_id,
                param_int1: Math.trunc(currentTime),
                param_int2: Math.trunc(duration),
                /*以下两个为可选参数*/
                param_str2: that.taskId ? that.taskId : '',
                param_str3: that.microLessonList[0].taskitem_id ? that.microLessonList[0].taskitem_id : '',
            }).then(res => {
                if (res.errcode >= that.$consts.biz.OK) {

                }
            })
        },
        onTapPraise() {
            const that = this
            var microLesson = [...that.microLessonList][0]
            doAction({
                action_code: microLesson.is_praised == '0' ? 'LN00' : 'LN01',
                param_str2: microLesson.lesson_id
            }).then(res => {
                if (res.errcode >= that.$consts.biz.OK) {
                    if(res.data.errcode >= that.$consts.biz.OK) {
                        microLesson.is_praised = microLesson.is_praised == '0' ? '1' : '0'
                        that.$set(that.microLessonList,0,microLesson)
                        that.$message.success(that.$consts.biz.OK_MSG)
                    }else {
                        that.$message.error(that.$consts.biz.FAIL_MSG)
                    }
                } else {
                    that.$message.error(that.$consts.biz.FAIL_MSG)
                }
            })
        },
        onTapFavorate() {
            const that = this
            var microLesson = [...that.microLessonList][0]
            doAction({
                action_code: microLesson.is_favorated == '0' ? 'LN10': 'LN11',
                param_str2: microLesson.lesson_id
            }).then(res => {
                if (res.errcode >= that.$consts.biz.OK) {
                    if(res.data.errcode >= that.$consts.biz.OK) {
                        microLesson.is_favorated = microLesson.is_favorated == '0' ? '1' : '0'
                        that.$set(that.microLessonList,0,microLesson)
                        that.$message.success(that.$consts.biz.OK_MSG)
                    }else {
                        that.$message.error(that.$consts.biz.FAIL_MSG)
                    }
                }else {
                    that.$message.error(that.$consts.biz.FAIL_MSG)
                }
            })
        },
        onCreateTask() {
            const that = this
            var microLesson = [...that.microLessonList][0]
            doAction({
                action_code: 'LNC0',
                param_str2: microLesson.lesson_id
            }).then(res => {
                if (res.errcode >= that.$consts.biz.OK) {
                    if(res.data.errcode >= that.$consts.biz.OK) {
                        that.$success({
                            title: '操作成功',
                            // JSX support
                            content: '已为您创建学习任务，记得完成哦'
                        })
                    }else {
                        that.$message.error(res.data.errmsg)
                    }
                }else {
                    that.$message.error(that.$consts.biz.FAIL_MSG)
                }
            })
        },
        onPlayCatelog(time_begin) {
            const that = this
            if (time_begin == null || time_begin == undefined) {
                return
            }
            that.seekPlay(time_begin)
            that.playIndex = 0
        },
        doDownload(url) {
            window.open(url)
        },
        saveNotes() {
            const that = this
            var microLesson = [...that.microLessonList][0]
            doAction({
                action_code: 'LNN0',
                param_str2: microLesson.lesson_id,
                param_str5: that.editorContent
            }).then(res => {
                if (res.errcode >= that.$consts.biz.OK) {
                    if(res.data.errcode >= that.$consts.biz.OK) {
                        that.$message.success('已成功保存笔记')
                    }else {
                        that.$message.error(res.data.errmsg)
                    }
                }else {
                    that.$message.error(that.$consts.biz.FAIL_MSG)
                }
            })
        },
        hideQuesModal() {
            const that = this
            //是否已经选择了答案,否则直接提示
            if (!that.lessonPops[that.lessonPopIndex].answer){
                that.$message.error('请选择答案')
                return;
            }

            //如果已经提交过答案，则继续播放，关闭弹出层
            if (that.lessonPops[that.lessonPopIndex].boRight == '1' ||that.lessonPops[that.lessonPopIndex].boRight == '0' ){
                that.quesModel = false
                videojs(this.$refs.videoRef).play()
                return
            }

            //如果没有提交过答案，则检查答案正确性，此处只做本地检查即可。无论正确与否，都允许关闭继续听课
            if (that.$cmyUtils.sortAnswer(that.lessonPops[that.lessonPopIndex].answer) == that.lessonPops[that.lessonPopIndex].answerok){
                that.$set(that.lessonPops[that.lessonPopIndex],"boRight","1");
                that.quesModelBtnText = '点此继续听课'
            }
            else {
                that.$set(that.lessonPops[that.lessonPopIndex],"boRight","0");
                that.quesModelBtnText = '点此继续听课'
            }

            that.quesModel = false
        },
        gotoTest() {
            const that = this
            const player = videojs(that.$refs.videoRef)
            player.pause()
            that.onPlayerEnded(player)
            that.SET_REFRESH_TRAINING_TASK_ITEM(!that.refreshTrainingTaskItem)


            //如果需要考试
            if (that.microLessonList[0].paper_id && that.microLessonList[0].paper_id != '') {
                that.$router.push({path: '/student/examination?taskId=' + that.taskId + '&taskItemId=' + that.microLessonList[0].taskitem_id})
                that.SET_TRAINING_VISIBLE(false)
            }
        },
        gotoTestRead() {
            const that = this
            //保存进度
            that.saveProgress(1,1)
            that.SET_REFRESH_TRAINING_TASK_ITEM(!that.refreshTrainingTaskItem)
            //如果需要考试
            if (that.microLessonList[0].paper_id && that.microLessonList[0].paper_id != '') {
                that.$router.push({path: '/student/examination?taskId=' + that.taskId + '&taskItemId=' + that.microLessonList[0].taskitem_id})
                that.SET_TRAINING_VISIBLE(false)
            }
        },
        onClickConfirm() {

            const that = this
            // 视频课程
            if(that.microLessonList.length > 0 && that.microLessonList[0].media_type == '1') {
                //学习进度不能为0
                if (that.microLessonList[0].item_progress == 0) {
                    that.$message.error('请首先完成视频学习')
                    return
                }
                //如果有限制快进，那么必须学习完成
                if (that.microLessonList[0].limit_fastforward == '1' ){
                    //没有观看完成，而且没有已经通过测试
                    if (that.microLessonList[0].video_length > 0 && that.microLessonList[0].item_progress < that.microLessonList[0].video_length - 60 && that.microLessonList[0].is_passed != '1'){
                        that.$message.error('该视频不支持立即结束，请完整观看')
                        return
                    }
                }
                if (that.microLessonList[0].item_progress > 0 && that.microLessonList[0].item_progress < that.microLessonList[0].video_length && that.microLessonList[0].is_passed != '1') {
                    //提示
                    if (that.microLessonList[0].paper_id && that.microLessonList[0].paper_id != '') {
                        that.$confirm({
                            centered: true,
                            title: '温馨提示',
                            content: '是否确认立刻完成视频学习，并开始考试？',
                            okText: '立即考试',
                            okType: 'primary',
                            cancelText: '取消',
                            onOk() {
                                that.gotoTest()
                            },
                            onCancel() {}
                        })
                    }else {
                        that.$confirm({
                            centered: true,
                            title: '温馨提示',
                            content: '是否确认立刻完成视频学习？',
                            okText: '立即考试',
                            okType: 'primary',
                            cancelText: '取消',
                            onOk() {
                                that.gotoTest()
                            },
                            onCancel() {}
                        })
                    }
                } else {
                    that.gotoTest()
                }
            }
            // 图文课程
            if(that.microLessonList.length > 0 && that.microLessonList[0].media_type != '1') {
                //如果是普通图文课程（总长度设定为1,item_progeres为1表示确认完成了）
                if (that.microLessonList[0].item_progress != 1) {
                    if (that.microLessonList[0].paper_id && that.microLessonList[0].paper_id != '') {
                        that.$confirm({
                            centered: true,
                            title: '温馨提示',
                            content: '是否确认立刻完成学习，并开始考试？',
                            okText: '立即考试',
                            okType: 'primary',
                            cancelText: '取消',
                            onOk() {
                                that.gotoTestRead()
                            },
                            onCancel() {}
                        })
                    }else {
                        that.$confirm({
                            centered: true,
                            title: '温馨提示',
                            content: '是否确认立刻完成学习？',
                            okText: '立即完成',
                            okType: 'primary',
                            cancelText: '取消',
                            onOk() {
                                that.gotoTestRead()
                            },
                            onCancel() {}
                        })
                    }
                }else {
                    that.gotoTestRead()
                }
            }
        },

    },
    beforeDestroy() {
        if(this.player) {
            videojs(this.$refs.videoRef).dispose()
        }
    },
    beforeRouteLeave(to, from, next) {
        if(this.player) {
            videojs(this.$refs.videoRef).dispose()
        }
        next()
    }
}
